<!-- 简历管理 -->
<template>
  <div class="wrap">
    <el-select v-model="valueType" placeholder="线上" @change="Handle">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <!-- 导入简历 -->
    <!-- <el-button style="margin-left:10px" @click="dialogVisible = true" v-if="isXian">导入简历</el-button> -->
    <!-- 创建简历 -->
    <!-- <el-button style="margin-left: 10px" @click="is_create_resume = true"
      >创建简历</el-button
    > -->
    <el-button type="warning" style="margin-left: 10px" plain @click="is_seach = true">筛 选</el-button>
    <el-dialog title="筛选" :visible.sync="is_seach" width="500px">
      <el-form label-width="70px" :model="formSeach">
        <el-form-item label="姓名">
          <el-input
            v-model="formSeach.name"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            v-model.number="formSeach.phone"
            placeholder="请输入手机号"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select
            style="width: 100%"
            v-model="formSeach.gender"
            placeholder="请选择性别"
          >
            <el-option label="男" value="男"> </el-option>
            <el-option label="女" value="女"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄">
          <el-select
            v-model="formSeachAge"
            placeholder="请选择年龄"
            style="width: 100%"
          >
            <el-option
              v-for="item in optionsAge"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="简历状态">
          <el-select
            style="width: 100%"
            v-model="formSeach.state"
            placeholder="请选择简历状态"
          >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.type"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学历">
          <el-select
            style="width: 100%"
            v-model="formSeach.education"
            placeholder="请选择学历"
          >
            <el-option
              v-for="item in optionsEdu"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源">
          <el-select
            style="width: 100%"
            v-model="formSeach.source"
            placeholder="请选择来源"
          >
            <el-option label="微信" :value="0"></el-option>
            <el-option label="抖音" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="期望岗位">
          <el-cascader
            style="width: 100%"
            placeholder="请选择期望岗位"
            v-model="jd_id"
            :options="indry"
          ></el-cascader>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="seachClearClick">清 空</el-button>
        <el-button @click="is_seach = false">取 消</el-button>
        <el-button type="primary" @click="seachClick">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="创建简历"
      :visible.sync="is_create_resume"
      width="30%"
      :before-close="
        () => {
          is_create_resume = false;
        }
      "
    >
      <el-form ref="form" :model="createResumeData" label-width="80px">
        <h3>基本信息</h3>
        <el-form-item label="姓名" required>
          <el-input
            placeholder="请输入姓名"
            v-model="createResumeData.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" required>
          <el-select
            style="width: 100%"
            v-model="createResumeData.gender"
            placeholder="请选择性别"
          >
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄" required>
          <el-input
            placeholder="请输入年龄"
            v-model="createResumeData.age"
            maxlength="2"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="学历" required>
          <el-select
            style="width: 100%"
            v-model="createResumeData.education_background"
            placeholder="请选择学历"
          >
            <el-option
              v-for="item in optionsEdu"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系方式" required>
          <el-input
            placeholder="请输入联系方式"
            v-model="createResumeData.phone"
            type="number"
          ></el-input>
        </el-form-item>

        <el-form-item label="期望岗位" required>
          <el-cascader
            style="width: 100%"
            placeholder="请选择期望岗位"
            v-model="createResumeData.position"
            :options="indry"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="期望地区" required>
          <el-cascader
            style="width: 100%"
            placeholder="请选择期望地区"
            v-model="createResumeData.diqu"
            :options="optionsCity"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="期望薪资" required>
          <el-input
            style="width: 200px"
            placeholder="请输入最低薪资"
            v-model="createResumeData.salary_min"
            type="number"
          ></el-input
          >-
          <el-input
            style="width: 200px"
            placeholder="请输入最高薪资"
            v-model="createResumeData.salary_max"
            type="number"
          ></el-input
          >元
        </el-form-item>
        <el-form-item label="婚否" required>
          <el-select
            style="width: 100%"
            v-model="createResumeData.marriage_status"
            placeholder="请选择婚姻状态"
          >
            <el-option label="未婚" value="未婚"></el-option>
            <el-option label="已婚已育" value="已婚已育"></el-option>
            <el-option label="已婚未育" value="已婚未育"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="居住地" required>
          <el-input
            v-model="createResumeData.residence"
            placeholder="请输入居住地"
          ></el-input>
        </el-form-item>
        <el-form-item label="到岗时间" required>
          <el-date-picker
            v-model="createResumeData.arrivalTime"
            type="date"
            value-format="timestamp"
            placeholder="选择到岗日期"
          >
          </el-date-picker>
        </el-form-item>
        <h3>工作经历</h3>
        <div class="work_time" v-for="(list, index) in 3" :key="list">
          <el-form-item label="公司名称" :required="index == 0 ? true : false">
            <el-input
              v-model="onJob[index].company_name"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="在职职位" :required="index == 0 ? true : false">
            <el-input
              v-model="onJob[index].inServicePosition"
              placeholder="请输入在职职位"
            ></el-input>
          </el-form-item>

          <el-form-item label="在职时间" :required="index == 0 ? true : false">
            <el-date-picker
              v-model="onJob[index].WorkingHours"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="在职薪资" :required="index == 0 ? true : false">
            <div class="salary">
              <el-input
                v-model="onJob[index].salary_min"
                placeholder="请输入最低薪资"
                style="width: 260px"
              ></el-input
              >&nbsp; -&nbsp;
              <el-input
                v-model="onJob[index].salary_max"
                placeholder="请输入最高薪资"
                style="width: 260px"
              ></el-input
              >&nbsp;&nbsp;&nbsp;元
            </div>
          </el-form-item>
        </div>
        <h3>教育经历</h3>
        <el-form-item label="学校名称" required>
          <el-input
            placeholder="请输入学校名称"
            v-model="createResumeData.school"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业名称" required>
          <el-input
            placeholder="请输入专业名称"
            v-model="createResumeData.major"
          ></el-input>
        </el-form-item>
        <el-form-item label="毕业时间" required>
          <el-date-picker
            style="width: 100%"
            v-model="createResumeData.biye_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-m-d"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input
            placeholder="请输入邮箱"
            v-model="createResumeData.email"
          ></el-input>
        </el-form-item>
        <h3>自我评价</h3>
        <el-form-item label="自我评价">
          <el-input
            placeholder="请输入自我评价"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            v-model="createResumeData.self_evaluation"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="is_create_resume = false">取 消</el-button>
        <el-button type="primary" class="btn" @click="create_resume"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="导入简历"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="tips">
        提示：请严格按照格式上传Excel文件，否则可能会失败
        <el-tooltip
          class="item"
          effect="dark"
          content="请严格按照模板格式上传Excel文件，且保证每条内容中间没有间隔，否则可能会上传失败！！"
          placement="bottom"
        >
          <b @click="downxlc">点击下载模板文件</b>
        </el-tooltip>
      </div>

      <el-upload
        class="upload-demo"
        drag
        action="/api/hr24_ee/tool/v1/excel_create_resume/"
        name="excel_file"
        :headers="{ token }"
        v-loading="load_upload"
        :on-success="handlesucc"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          只能上传xls/xlsx文件，且不超过2M
        </div>
      </el-upload>
    </el-dialog>

    <el-dialog title="导入失败的简历" :visible.sync="dialogTableVisible">
      <el-table :data="gridData">
        <el-table-column property="name" label="姓名"></el-table-column>
        <el-table-column property="phone" label="手机号"></el-table-column>
        <!-- <el-table-column property="id_card_num" label="身份证号"></el-table-column> -->
        <el-table-column property="msg" label="失败原因"></el-table-column>
      </el-table>
    </el-dialog>
    <el-table
      :data="tableData"
      stripe
      style="margin-top: 30px; width: 100%; min-height: 70vh"
      @row-click="goDetail"
      v-loading="loading"
    >
      <el-table-column
        prop="source_port"
        label="求职状态"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <div
            :style="{
              color: list[scope.row.career_progress].color,
            }"
          >
            {{ list[scope.row.career_progress].text }}
          </div>
        </template>
      </el-table-column>
      
      <el-table-column
        prop="name"
        label="姓名"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="gender"
        label="性别"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="age"
        label="年龄"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="联系方式"
        width="120"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="resume_education_experience_list[0].education_background"
        label="学历"
        width="100"
        align="center"
      ></el-table-column>

      <el-table-column label="期望薪资" align="center" width="150">
        <template
          v-if="
            scope.row.resume_expect_position_list &&
            scope.row.resume_expect_position_list.length > 0
          "
          slot-scope="scope"
        >
          {{ scope.row.resume_expect_position_list[0].salary_min }}-{{
            scope.row.resume_expect_position_list[0].salary_max
          }}元
        </template>
      </el-table-column>
      <el-table-column
        prop="expect_position_name_str"
        label="期望岗位"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column label="期望地区" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          {{ scope.row.expect_place_name }}
        </template>
      </el-table-column>

      
      <el-table-column
        prop="clue_jd_name"
        label="浏览职位"
        show-overflow-tooltip
        align="center"
      ></el-table-column>

      <!-- <el-table-column label="简历类别" align="center">
        <template slot-scope="scope">{{scope.row.expect_place_name}}</template>
      </el-table-column>-->
      <el-table-column label="简历状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0">未标记</span>
          <i
            v-else
            :class="colorStatus[scope.row.status].iconfont"
            :style="{ color: colorStatus[scope.row.status].color }"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="投递时间"
        align="center"
      ></el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageTotal"
        :page-size="20"
        :current-page="pageNow"
        hide-on-single-page
        @current-change="pageNowHandler"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getResumeManage,
  upload_file,
  create_resume,
  setting_interview,
} from "@/api/tool/index";
import { show_position_json } from "@/api/tool/publish.js";
import { showCity } from "@/api/resume";
import { profession, educationBackground, age, sex } from "@/utils/searchtype";
import axios from "axios";

let id = "";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      dialogVisible: false,
      gridData: [],
      dialogTableVisible: false,
      token: "",
      fileList: [],
      load_upload: false,
      loading: true,
      tableData: [],
      //总页码
      pageTotal: null,
      pageNow: 1,
      //简历状态引入方式只支持单色
      //不合格，可复试,发offer,未入职，
      colorStatus: [
        { color: "#ccc", iconfont: "iconfont iconbuhege" },

        { color: "#FF435A", iconfont: "iconfont iconbuhege" },
        { color: "#ff9900", iconfont: "iconfont iconkefushi" },
        { color: "#3EFF59", iconfont: "iconfont iconfaoffer" },
        { color: "#1D7BD8", iconfont: "iconfont iconweiruzhi" },
      ],
      statusList: [
        { color: "#ccc", type: "未标记", id: 0 },
        { color: "#FF435A", type: "不合格", id: 1 },
        { color: "#ff9900", type: "可复试", id: 2 },
        { color: "#3EFF59", type: "发offer", id: 3 },
        { color: "#1D7BD8", type: "未入职", id: 4 },
      ],
      options: [
        // { label: '不限', value: 0 },
        { label: "线上", value: 0 },
        { label: "线下", value: 1 },
      ],
      jd_id: [],
      valueType: 0,
      is_create_resume: false,
      createResumeData: {},
      optionsCity: [],
      indry: [],
      search_input: "",
      position_time: [],
      onJob: [
        {
          company_name: "",
          inServicePosition: "",
          WorkingHours: [],
          salary_min: "",
          salary_max: "",
        },
        {
          company_name: "",
          inServicePosition: "",
          WorkingHours: [],
          salary_min: "",
          salary_max: "",
        },
        {
          company_name: "",
          inServicePosition: "",
          WorkingHours: [],
          salary_min: "",
          salary_max: "",
        },
      ],
      list: [
        {
          id: 0,
          text: "未选择",
          color: "#9a9a9a",
        },
        {
          id: 1,
          text: "急需找工作",
          color: "#f00",
        },
        {
          id: 2,
          text: "随便看看",
          color: "#e2a826",
        },
        {
          id: 3,
          text: "考虑机会",
          color: "#1296db",
        },
        {
          id: 4,
          text: "暂不考虑",
          color: "#9a9a9a",
        },
      ],
      is_seach: false,
      formSeach: {},
      formSeachAge: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //筛选
    seachClick() {
      this.pageNow = 1;
      this.is_seach = false;
      this.getResumeManage();
    },
    seachClearClick() {
      this.formSeach = {};
      this.jd_id = [];
      this.formSeachAge = "";
      this.pageNow = 1;
      this.is_seach = false;

      this.getResumeManage();
    },

    //创键简历
    create_resume() {
      console.log(this.createResumeData);
      if (
        this.createResumeData.name == undefined ||
        this.createResumeData.name == ""
      ) {
        this.$message.warning("请输入姓名");
        return false;
        console.log("aaaaa");
      } else if (
        this.createResumeData.gender == undefined ||
        this.createResumeData.gender == ""
      ) {
        this.$message.warning("请输入性别");
        return false;
      } else if (
        this.createResumeData.age == undefined ||
        this.createResumeData.age == ""
      ) {
        this.$message.warning("请输入年龄");
        return false;
      } else if (
        this.createResumeData.age < 16 ||
        this.createResumeData.age > 55
      ) {
        this.$message.warning("年龄不符合规范");
        return false;
      } else if (
        this.createResumeData.education_background == undefined ||
        this.createResumeData.education_background.length == 0
      ) {
        this.$message.warning("请选择学历");
        return false;
      } else if (
        !/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.createResumeData.phone)
      ) {
        this.$message.warning("请输入联系方式");
        return false;
      } else if (
        this.createResumeData.position == undefined ||
        this.createResumeData.position.length == 0
      ) {
        this.$message.warning("请选择期望岗位");
        return false;
      } else if (
        this.createResumeData.diqu == undefined ||
        this.createResumeData.diqu.length == 0
      ) {
        this.$message.warning("请选择期望地区");
        return false;
      } else if (
        this.createResumeData.salary_min == undefined ||
        this.createResumeData.salary_min == ""
      ) {
        this.$message.warning("请输入期望薪资最低薪资");
        return false;
      } else if (
        this.createResumeData.salary_max == undefined ||
        this.createResumeData.salary_max == ""
      ) {
        this.$message.warning("请输入期望薪资最高薪资");
        return false;
      } else if (
        Number(this.createResumeData.salary_max) <=
        Number(this.createResumeData.salary_min)
      ) {
        this.$message.warning("期望薪资有误");
        return false;
      } else if (!this.createResumeData.marriage_status) {
        this.$message.warning("请选择婚否");
        return false;
      } else if (!this.createResumeData.residence) {
        this.$message.warning("请输入居住地");
        return false;
      } else if (!this.createResumeData.arrivalTime) {
        this.$message.warning("请选择到岗时间");
        return false;
      } else if (
        this.onJob[0].company_name == "" ||
        this.onJob[0].inServicePosition == "" ||
        this.onJob[0].WorkingHours.length == 0 ||
        this.onJob[0].salary_min == "" ||
        this.onJob[0].salary_max == ""
      ) {
        this.$message.warning("请完善工作经历");
        return false;
      } else if (
        !this.createResumeData.school ||
        !this.createResumeData.major ||
        !this.createResumeData.biye_time
      ) {
        this.$message.warning("请完善教育经历");
        return false;
      }
      let jobDate = this.onJob.filter((res, index) => {
        return res.company_name != "";
      });
      console.log(this.onJob);
      let arr = [];
      jobDate.map((res) => {
        console.log(res.company_name);
        let obj = {
          company_name: res.company_name,
          start_year:
            res.WorkingHours.length > 0
              ? Number(res.WorkingHours[0].split("-")[0])
              : 0,
          start_month:
            res.WorkingHours.length > 0
              ? Number(res.WorkingHours[0].split("-")[1])
              : 0,
          end_year:
            res.WorkingHours.length > 0
              ? Number(res.WorkingHours[1].split("-")[0])
              : 0,
          end_month:
            res.WorkingHours.length > 0
              ? Number(res.WorkingHours[1].split("-")[1])
              : 0,
          position_name: res.inServicePosition || "",
          floor: Number(res.salary_min) || 0,
          upper: Number(res.salary_max) || "",
        };
        arr.push(obj);
      });
      let obj = {
        name: this.createResumeData.name,
        gender: this.createResumeData.gender,
        phone: this.createResumeData.phone,
        marriage_status: this.createResumeData.marriage_status || "",
        self_evaluation: this.createResumeData.self_evaluation || "",
        email: this.createResumeData.email || "",
        place_of_abode: this.createResumeData.residence,
        arrival_time: this.createResumeData.arrivalTime / 1000,
        age: this.createResumeData.age,
        resume_expect_position_list: {
          position_id:
            this.createResumeData.position[
              this.createResumeData.position.length - 1
            ],
          city_id: this.createResumeData.diqu[1],
          district_id: this.createResumeData.diqu[2],
          salary_min: Number(this.createResumeData.salary_min),
          salary_max: Number(this.createResumeData.salary_max),
        },
        resume_education_experience_list: {
          school: this.createResumeData.school || "",
          education_background:
            this.createResumeData.education_background || "",
          major: this.createResumeData.major || "",
          start_year:
            this.createResumeData.biye_time == undefined
              ? ""
              : this.createResumeData.biye_time.length > 0
              ? this.createResumeData.biye_time[0].split("-")[0]
              : "",
          start_month:
            this.createResumeData.biye_time == undefined
              ? ""
              : this.createResumeData.biye_time.length > 0
              ? this.createResumeData.biye_time[0].split("-")[1]
              : "",
          end_year:
            this.createResumeData.biye_time == undefined
              ? ""
              : this.createResumeData.biye_time.length > 0
              ? this.createResumeData.biye_time[1].split("-")[0]
              : "",
          end_month:
            this.createResumeData.biye_time == undefined
              ? ""
              : this.createResumeData.biye_time.length > 0
              ? this.createResumeData.biye_time[1].split("-")[1]
              : "",
        },
        resume_work_experience_list: arr,
      };
      create_resume(obj).then((res) => {
        if (res.code == 0) {
          console.log(res);
          this.loading = true;
          this.is_create_resume = false;
          this.$message.success(res.msg);
          this.Handle(this.valueType);
          this.pageNowHandler(1);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    beforeAvatarUpload(file) {
      console.log(file, "before");
      this.load_upload = true;
      const isJPG =
        file.name.indexOf("xlsx") != -1 || file.name.indexOf("xls") != -1;
      console.log(isJPG, "是否");
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 xls/xlsx 格式!");
      }
      return isJPG;
    },

    handlesucc(response, file, fileList) {
      this.load_upload = false;
      let response1 = response;
      console.log(file, fileList, response1, "success");
      //上传成功的简历 如果有传失败的简历则显示出来
      this.$message.success(`传入成功${response1.data.use_count}份简历`);

      if (response1.data.mistake_count) {
        this.dialogTableVisible = true;
      } else {
        this.dialogTableVisible = false;
      }
      this.gridData = response1.data.mistake_resume_list;
      this.dialogVisible = false;
    },

    // 下载excal模板
    downxlc() {
      try {
        var elemIF = document.createElement("iframe");
        elemIF.src =
          "https://oss.hr24.com.cn/public/fa16c300303f46249baa7d9431a84c95.xls?BUCKET=xls-bucket";
        elemIF.style.display = "none";
        document.body.appendChild(elemIF);
      } catch (e) {
        alert("下载异常！");
      }
    },

    Handle(value) {
      console.log(value);
      this.valueType = value;
      this.pageNow = 1;
      this.formSeach = {};
      this.jd_id = [];

      this.getResumeManage();
    },
    goDetail(row, column, event) {
      console.log(row, column, event);
      sessionStorage.setItem("goDetail", 22);
      this.$router.push({
        path: "/tooldetails",
        query: {
          id: row.resume_id,
          id_resume: row.id,
          is_show: this.valueType,
        },
      });
    },

    handleClose() {
      this.dialogVisible = false;
    },

    pageNowHandler(page) {
      if (this.pageNow != page) {
        console.log(11);
        this.pageNow = page;
        this.getResumeManage();
      }
    },
    getResumeManage() {
      console.log("activated");
      let obj = { page: this.pageNow, is_online: this.valueType, key: {} };
      let index = 0;
      if (JSON.stringify(this.formSeach) != "{}") {
        let phone = this.formSeach.phone;
        if (phone) {
          if (typeof phone != "number" && phone.length != 11) {
            this.$message.warning("请正确输入手机号");
            index++;
            return false;
          }
        }
        obj.key = this.formSeach;
      }
      let age = this.formSeachAge;
      console.log(age, "age");
      if (age) {
        if (age === "年龄不限") {
          obj.key.minage = 0;
          obj.key.maxage = 99;
        } else if (age === "45岁以上") {
          obj.key.minage = 45;
          obj.key.maxage = 99;
        } else {
          let value = age.split("-");
          obj.key.minage = value[0];
          obj.key.maxage = value[1].split("岁")[0];
        }
      }
      if (index > 0) {
        console.log("index", index);
        return false;
      }
      if (obj.key.education == "学历不限") {
        delete obj.key.education;
      }
      this.loading = true;
      getResumeManage(obj).then((res) => {
        this.loading = false;
        this.is_seach = false;
        this.is_create_resume = false;
        this.tableData = res.data.resume_list;
        this.pageTotal = res.data.all_counts;
        console.log("acascascasccac");
        sessionStorage.setItem("goDetail", 0);
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.token = window.sessionStorage.getItem("token");

    this.optionsProfession = profession;
    this.optionsEdu = educationBackground;
    this.optionsAge = age;
    this.optionsSex = sex;
    showCity({}).then((res) => {
      console.log(res);
      this.optionsCity = res.data;
    });
    // axios.get('/static/indry.json').then((res) => {
    //   console.log(res)
    //   this.indry = res
    // })
    show_position_json().then((res) => {
      if (res.code == 0) {
        this.indry = res.data;
      }
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {
    console.log("this.$route", this.$route);
    if (sessionStorage.getItem("goDetail") != 22) {
      this.formSeach = {};
      this.pageNow = 1;
      this.valueType = 0;
    }
    this.getResumeManage();
  }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  min-width: 1200px;
  padding-bottom: 0;
  position: relative;
  margin-left: -50px;
}
// 分页
.pagination {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 40px;
  text-align: center;
}

.upload-demo {
  text-align: center;
  // position: absolute;
  // z-index: 999;
  // top: 30px;
  // left: 300px;
}

.tips {
  font-size: 14px;
  margin-bottom: 10px;
}
.tips b {
  font-size: 18px;
  color: red;
  font-weight: normal;
}
.source_port img {
  width: 20px;
  height: 20px;
  margin: 0px 4px 0 0px;
}
.source_port img:last-child {
  width: 19px;
  height: 19px;
  margin: 0px 4px 0 0px;
}
</style>
